<!--suppress CssUnusedSymbol -->
<template>
  <v-app>
    <template v-if="loaded">
      <DemoUserRoleNotification v-if="isDemoUserRole" />
      <v-main v-if="isUserLoggedIn" class="main-container">
        <router-view></router-view>
      </v-main>
      <PublicTemplate v-else-if="$route.path == '/'">
        <router-view name="public" />
      </PublicTemplate>
      <PublicTemplate v-else>
        <router-view />
      </PublicTemplate>
      <dialog-login @reset-password="showResetModal" />
      <dialog-register />
      <v-dialog v-model="resetModal" max-width="420px">
        <reset-password-request-form v-if="resetModal" @cancel="resetModal = false" />
      </v-dialog>
      <ToastStack />
    </template>
    <Reporter />
  </v-app>
</template>

<script>
import CachedDataMixin from 'App/mixins/CachedDataMixin';
import ItemMaster from 'App/ItemMaster';
import RBACMixin from 'App/mixins/RBACMixin';
import DemoUserRoleNotification from 'Components/DemoUserRoleNotification';

import { ROLE_DEMO_CUSTOMER } from '@/app/constants/Roles';
import {
  CACHED_DATA_CATEGORIES,
  CACHED_DATA_MEDIA_RESOURCE_TYPES,
  CACHED_DATA_MODULES,
  CACHED_DATA_PATENTS_AMOUNT,
  CAT_PATENTS,
} from 'App/utils/Collections';

const LOADER_CLASS = 'show-loader';
const T24H = 3600 * 24;

export default {
  name: 'App',
  mixins: [CachedDataMixin, RBACMixin],
  components: {
    DemoUserRoleNotification,
    Reporter: () => import('Components/Reporter'),
    ToastStack: () => import('App/plugins/toaster/ToastStack'),
    ResetPasswordRequestForm: () => import('Components/RequestPasswordResetForm'),
    DialogLogin: () => import('Components/DialogLogin'),
    DialogRegister: () => import('Components/DialogRegister'),
    // eslint-disable-next-line vue/no-unused-components
    PublicTemplate: () => import('Components/public/Template'),
  },
  data() {
    return {
      loaded: false,
      resetModal: false,
      redirect: new URLSearchParams(location.search).get('r'),
      isUserLoggedIn: false,
    };
  },
  computed: {
    Interface() {
      return this.$store.state.Interface;
    },
    User() {
      return this.$store.state.user;
    },
    isDemoUserRole() {
      return this.User.logged && this.hasRole(ROLE_DEMO_CUSTOMER);
    },
  },
  created() {
    if (this.$buildTimestamp && this.$store.getters.builtAt !== this.$buildTimestamp) {
      this.$store.commit('setVar', { buildTimestamp: this.$buildTimestamp });
      this.invalidateCachedData();
    }
    this.User.addEventListener('login', this.onLogin)
      .addEventListener('logout', this.onLogout)
      .checkAuthToken()
      .then((ok) => {
        if (ok) {
          this.setLoaded();
        } else {
          this.onCheckFail();
        }
      });
    this.unsubStore = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setLang') {
        let locale = mutation.payload;
        if (locale === 'zh') {
          locale = 'zhHans';
        }
        this.invalidateCachedData();
        this.$store.commit('clearRecentlyAdded');
        this.$vuetify.lang.current = locale;
        this.loadRefData();
      }
    });
  },
  methods: {
    loadFonts() {
      const el = document.createElement('link');
      el.setAttribute('rel', 'stylesheet');
      el.setAttribute('href', 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
      document.head.appendChild(el);
    },
    setLoaded() {
      this.loadFonts();
      this.toggleLoader(true);
      this.loaded = true;
    },
    async loadModules() {
      if (this.needCachedDataUpdate(CACHED_DATA_MODULES)) {
        return ItemMaster.Dataman.DB.getModules().then((res) => {
          const modules = Object.fromEntries(res.data.map((el) => [el.alias.toLowerCase(), el.id]));
          this.$store.dispatch('setModules', modules);
          this.$store.commit('setCachedData', {
            key: CACHED_DATA_MODULES,
            data: modules,
            lifetime: T24H,
          });
        });
      }
    },
    async loadMediaResourceTypes() {
      if (this.needCachedDataUpdate(CACHED_DATA_MEDIA_RESOURCE_TYPES)) {
        return ItemMaster.Dataman.Filter.getMediaResourceTypes().then((res) => {
          const map = Object.fromEntries(res.data.map((el) => [el.id, el]));
          this.$store.commit('setCachedData', {
            key: CACHED_DATA_MEDIA_RESOURCE_TYPES,
            data: map,
            lifetime: 3600 * 8,
          });
        });
      }
    },
    async getPatentsAmount() {
      if (this.needCachedDataUpdate(CACHED_DATA_PATENTS_AMOUNT)) {
        const amount = await ItemMaster.count({ category: CAT_PATENTS });
        this.cacheData(CACHED_DATA_PATENTS_AMOUNT, amount, T24H);
      }
    },
    onLogin() {
      this.isUserLoggedIn = true;
      this.$store.dispatch('setIsAdmin', this.User.isAdmin);
      this.$store.dispatch('setFlag', { flag: 'loaded', state: true });
      if (this.User.isAdmin) {
        this.loadCategories();
      }
      ItemMaster.Dataman.showDrafts(this.User.isAdmin);
      this.setLoaded();
      this.backToLocation().then(() => {
        this.loadRefData();
        if (this.isUserLoggedIn) {
          this.User.loadProfile();
        }
      });
    },
    onLogout() {
      this.onCheckFail();
      this.$store.dispatch('logout');
    },
    onCheckFail() {
      this.isUserLoggedIn = false;
      this.setLoaded();
      if (!this.$route.meta.isPublic && this.$route.path !== '/' && !this.$route.path.includes('/reset-password/')) {
        let url = '/';
        if (this.$route.path !== '/') {
          url += `?r=${this.$route.path}`;
        }
        this.$router.push(url);
      }
    },
    async loadRefData() {
      return Promise.all([this.loadModules(), this.loadMediaResourceTypes(), this.getPatentsAmount()]);
    },
    async backToLocation() {
      const { query } = this.$route;
      if (query.r) {
        this.$router.push({ path: query.r });
      }
    },
    showResetModal() {
      this.Interface.login.hide();
      this.$nextTick(() => {
        this.resetModal = true;
      });
    },
    toggleLoader(state) {
      const root = document.querySelector('html');
      if (state) {
        root.classList.remove(LOADER_CLASS);
      } else {
        root.classList.add(LOADER_CLASS);
      }
    },
    async checkUpdate() {
      const text = await fetch(location.origin).then((res) => res.text());
      const doc = new DOMParser().parseFromString(text, 'text/html');
      const buildTimestamp = doc.querySelector('html').dataset.buildTimestamp;
      if (this.$buildTimestamp !== buildTimestamp) {
        window.location.reload();
      }
    },
    async loadCategories() {
      if (this.needCachedDataUpdate(CACHED_DATA_CATEGORIES)) {
        const entries = await Promise.all([
          ItemMaster.Dataman.DB.categories.getReadable().then((res) => ['readable', res.data]),
          ItemMaster.Dataman.DB.categories.getWritable().then((res) => ['writable', res.data]),
        ]);
        this.cacheData(CACHED_DATA_CATEGORIES, Object.fromEntries(entries), 3600);
      }
    },
  },
};
</script>

<style scoped>
.app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.54);
}

.app-loading i {
  font-size: 6rem !important;
}

.app-loading span {
  padding-top: 15px;
  font-size: 1.25rem;
  letter-spacing: 1px;
}
.main-container {
  margin-left: 44px;
}
</style>
