import Dataman, { apiGet } from '@/app/Dataman';
import Backend from 'App/Dataman-Backend';

const { apiUrlProducts, apiUrlSuffix } = Backend.config;

export async function searchProducts(params, cat, headers) {
  const { clearParams, ...rest } = params;
  let imageParams = {};
  if (rest.imageSearch) {
    const formData = new FormData();
    formData.append('file', rest.imageSearch);
    formData.append('maxResults', 30);
    const res = await Dataman.DB.searchProductsByImage(formData);
    const ids = res.data.map((i) => i.id).join(',');
    imageParams.valueSequence = ids;
  }
  let preparedParams = {
    params: { ...rest, ...imageParams },
    clearParams,
    is_protected: true,
  };
  if (headers) {
    preparedParams.headers = headers.headers;
  }
  return apiGet(apiUrlProducts + apiUrlSuffix, preparedParams);
}
