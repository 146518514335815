import { CAT_TRADEMARKS } from '@/app/utils/Collections';
import { CATEGORY_PLURAL_TO_SINGLE } from '@/data/category-map';
import { isAuthTokenValid, decodeJWT } from '@/lib/Utils';
// import { env } from '../../project.config';
// import { ROLE_DIGITAL_HEALTHCARE, ROLE_STAFF } from 'App/constants/Roles';

let INSTANTIATED = false;
const EVENTS = new Map();
const RBAC = new Set();

function initUI() {
  return {
    bookmarks: [],
    filters: [],
    reports: [],
    perks: [],
  };
}

// noinspection EqualityComparisonWithCoercionJS
class UserModel {
  #emit = (event, ...args) => {
    const handlers = EVENTS.get(event);
    if (handlers?.length) {
      for (const handler of handlers) {
        if (typeof handler === 'function') {
          handler(...args);
        }
      }
    }
  };
  #setRoles = (roles) => {
    roles.forEach(({ name }) => RBAC.add(name.toLowerCase()));
  };
  #reset = () => {
    this.id = null;
    this.username = '';
    this.logged = false;
    this.token = null;
    this.profile = {};
    this.ui = {
      bookmarks: [],
      filters: [],
      reports: [],
      perks: [],
    };
    RBAC.clear();
  };

  id = null;
  username = '';
  logged = false;
  token = null;
  refresh_token = null;
  profile = {};
  ui = {};

  constructor(Dataman) {
    if (INSTANTIATED) {
      throw new Error('User instance already exists');
    }
    INSTANTIATED = true;
    this.DM = Dataman;
    this.ui = initUI();
  }

  setUI = (json) => {
    try {
      const ui = JSON.parse(json) || {};
      this.ui.perks = ui.perks || [];
    } catch (e) {
      console.error(e);
    }

    return this.ui;
  };
  loadBookmarks = async () => {
    const bookmarks = await this.DM.getBookmarks();
    return bookmarks.data || [];
  };
  loadFilters = async () => {
    const filters = await this.DM.getFilters();
    return filters.data || [];
  };
  loadProfile = async () => {
    const [user, bookmarks, filters] = await Promise.all([this.DM.getUser(), this.loadBookmarks(), this.loadFilters()]);
    const profile = user.data;
    this.#setRoles(profile.groups);
    this.setUI(profile.ui);
    this.profile = profile;
    this.#emit('profile', this);

    this.ui.bookmarks = bookmarks;
    this.ui.filters = filters;

    return profile;
  };

  updateProfile = async (fields) => {
    const data = {};
    if (!fields?.length) {
      fields = ['name', 'phone', 'company'];
    }
    fields.forEach((field) => {
      data[field] = this.profile[field];
    });
    return this.DM.updProfile(data);
  };
  getToken = async (credentials) => {
    const response = await this.DM.getToken(credentials);
    const { token, refresh_token } = response?.data || {};
    if (!token) {
      throw new Error('Can not get auth token');
    }
    return { token, refresh_token };
  };
  auth = async ({ token, refresh_token }) => {
    const { payload } = decodeJWT(token);
    this.token = token;
    this.refresh_token = refresh_token;
    this.id = payload.id;
    this.username = payload.username;
    payload.roles.forEach((role) => RBAC.add(role.toLowerCase()));
    this.logged = true;
    this.DM.setLocalToken({ token, refresh_token });
    this.DM.setToken({ token, refresh_token });
    // this.loadProfile().catch();
    this.#emit('login', this);
    return payload.id;
  };
  login = async (credentials) => this.auth(await this.getToken(credentials));
  logout = async () => {
    this.DM.clearLocalToken();
    this.#reset();
    this.#emit('logout');
  };
  checkAuthToken = async () => {
    const { token, refresh_token } = this.DM.getLocalToken();
    if (token && isAuthTokenValid(token)) {
      console.log('Auth: token valid');
      return this.auth({ token, refresh_token });
    } else if (refresh_token) {
      try {
        console.log('Auth: refreshing token');
        const newTokens = await this.DM.refreshToken(refresh_token);
        return this.auth(newTokens);
      } catch (err) {
        console.log('Auth: can not refresh token');
        console.error(err);
        this.DM.clearLocalToken();
        this.#reset();
        return false;
      }
    } else {
      console.log('Auth: no tokens found');
      this.DM.clearLocalToken();
      this.#reset();
      return false;
    }
  };
  updateUI = () => {
    this.profile.ui = JSON.stringify({
      ...this.ui,
      filters: this.ui.filters?.map((item) => JSON.stringify(item)) || null,
    });
    return this.updateProfile(['ui']);
  };
  clearUI = () => {
    this.profile.ui = JSON.stringify({});
    this.ui = initUI();
    return this.updateProfile(['ui']);
  };
  reportsCount = () => this.ui.reports.length;
  Dev = () => false;
  Help = () => false;
  Perk = (perk, attach = true) => {
    const index = this.ui.perks.indexOf(perk);
    if (!!attach && index < 0) {
      this.ui.perks.push(perk);
    }
    if (!attach && index > -1) {
      this.ui.perks.splice(index, 1);
    }
    return this.updateUI();
  };
  filters = {
    add: async (filter) => {
      const { data } = await this.DM.createFilters(filter);
      if (this.ui.filters === null) {
        this.ui.filters = [];
      }
      this.ui.filters.push(data);
      return data;
    },
    update: async (filter) => {
      const { data: updatedFilter } = await this.DM.updateFilter(filter.id, filter);
      this.ui.filters = this.ui.filters.map((oldFilter) =>
        oldFilter.id === updatedFilter.id ? updatedFilter : oldFilter
      );
    },
    remove: async (item) => {
      await this.DM.deleteFilter(item.id);
      this.ui.filters = this.ui.filters.filter(({ id }) => id !== item.id);
    },
    list: (module) => {
      return this.ui.filters.filter((el) => !module || el.module === module);
    },
    amount: () => this.ui.filters.length,
    shortlist: (module, len = 7) => {
      let filters = this.ui.filters;
      const usedLabels = [];
      filters = filters.filter((filter) => {
        if (usedLabels.includes(filter.label.trim())) {
          return false;
        }
        usedLabels.push(filter.label.trim());
        return true;
      });
      return filters.filter((el) => !module || el.module === module).slice(-len);
    },
    commonShortList: (module, len = 7) => {
      return this.ui.filters.filter((el) => (!module || el.module === module) && !el.isSuggested).slice(-len);
    },
  };
  bookmarks = {
    hasBookmark(item, bookmark) {
      return bookmark.itemId == item.id && bookmark.category == item.category;
    },
    exists: (item) => {
      return this.ui.bookmarks.some((bookmark) => {
        return this.bookmarks.hasBookmark(item, bookmark);
      });
    },
    findBookmark: (item) => {
      return this.ui.bookmarks.find((bookmark) => {
        return this.bookmarks.hasBookmark(item, bookmark);
      });
    },
    amount: () => this.ui.bookmarks.length,
    shortlist: (len = 7) => this.ui.bookmarks.slice(-len).reverse(),
    toggle: (item) => {
      const existingBookmark = this.bookmarks.findBookmark(item);
      if (existingBookmark) {
        return this.bookmarks.remove(existingBookmark);
      } else {
        return this.bookmarks.add(item);
      }
    },
    add: async (item) => {
      const { id, category } = item;
      const categoryField = CATEGORY_PLURAL_TO_SINGLE[category];

      const { data } = await this.DM.createBookmarks({
        [categoryField]: id,
      });

      this.ui.bookmarks.push(data);
    },
    remove: async (item) => {
      await this.DM.deleteBookmark(item.id);
      this.ui.bookmarks = this.ui.bookmarks.filter(({ id }) => id !== item.id);
    },
  };

  hasAccess = (category) => {
    const user_cats = [CAT_TRADEMARKS];

    if (!category || user_cats.includes(category)) {
      return true;
    }
    const cat = category.toLowerCase();
    return RBAC.has(cat) || RBAC.has(`role_${cat}`);
  };

  get isAdmin() {
    return RBAC.has('role_staff');
  }
}

UserModel.prototype.addEventListener = function (event, handler) {
  if (!EVENTS.has(event)) {
    EVENTS.set(event, []);
  }
  EVENTS.get(event).push(handler);
  return this;
};

UserModel.prototype.removeEventListener = function (event, handler) {
  const handlers = EVENTS.get(event);
  if (handlers?.length) {
    let index = handlers.indexOf(handler);
    while (index > 0) {
      handlers.splice(index, 1);
      index = handlers.indexOf(handler);
    }
  }
  return this;
};

export default UserModel;
